.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 297px);
  margin-top: 99px;
}

.page-not-found img {
  max-width: 600px;
  max-height: 600px;
}
@media (max-width: 800px) {
  .page-not-found img {
    max-width: 100%;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-transparent .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.95)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.bg-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d !important;
  }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbar-nav .dropdown-menu .media svg {
    width: 30px;
  }
  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;
  }
  .navbar-collapse .navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0;
  }
  .navbar-collapse .navbar-toggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
  }
  .navbar-collapse .navbar-toggler :nth-child(1) {
    transform: rotate(135deg);
  }
  .navbar-collapse .navbar-toggler :nth-child(2) {
    transform: rotate(-135deg);
  }
  .navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse .collapse-brand img {
    height: 36px;
  }
  .navbar-collapse .collapse-close {
    text-align: right;
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse 0.2s ease forwards;
  }
  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

.navbar-collapse {
  top: 75px;
}

.ant-select-selection-overflow {
  height: 43px !important;
}
a:hover {
  color: initial;
}
nav a:hover {
  color: white;
}

.ant-space-item:nth-child(1) {
  flex-grow: 1;
}
.ant-space-item:nth-child(2) {
  flex-grow: 1;
}

.button-loading {
  margin: auto;
  justify-content: center;
  display: flex;
}

.btn-danger {
  color: #fff;
  background-color: red;
  border-color: red;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.loading-page div span {
  font-size: 222px !important;
}

@media (max-width: 768px) {
  .nav-buttons-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nav-buttons-list > button {
    margin-right: 0 !important;
    margin-top: 10px ;
  }
}

.ant-modal-body{
  padding: 0;
}

/* .ant-form-item-explain-error{
  position: absolute;
    bottom: -25px;
} */
.input-group-alternative{
  box-shadow: none;
}


.cookies-accept{
  background-color: black;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items:center;
  color: white;
  
}
.cookies-accept > a:hover{
  color: #1890ff;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left:2px
}